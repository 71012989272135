import { token, IconButton } from '@zillow/constellation';
import styled from 'styled-components';
import { StyledProps } from './MenuItem.types';

export const MenuItemContainer = styled.li<StyledProps>`
    display: flex;
    align-self: stretch;
    padding: 0;
    position: relative;
    align-items: center;
    flex-wrap: wrap;

    /* Desktop */
    @media (max-width: 889px) {
        border-bottom: solid 1px ${token('colors.borderLight')};
    }

    ${(props) =>
        props.mobileOnly &&
        `
        @media (min-width: 890px) {
            display: none;
        }
    `}
`;

export const MenuLink = styled.a`
    display: flex;
    flex: 1;
    position: relative;
    height: 55px;
    align-items: center;
    font-family: ${token('fonts.sansSerif')};
    font-size: ${token('fontSizes.bodySmall')}px;
    line-height: 40px;
    padding: 0 ${token('spacing.sm')}px;
    color: ${token('colors.textDark')};
    text-decoration: none;
    white-space: nowrap;
    &.current {
        color: ${token('colors.brand')} !important;
        &:after {
            content: '';
            border-top: solid 3px ${token('colors.brand')};
            position: absolute;
            left: ${token('spacing.sm')}px;
            right: ${token('spacing.sm')}px;
            bottom: 0;
            z-index: 10;
            width: calc(100% - ${token('spacing.sm') * 2}px);
        }
    }
    &:visited {
        color: ${token('colors.textDark')};
    }
    &:hover {
        color: ${token('colors.brand')};
    }
    /* Mobile */
    @media (max-width: 889px) {
        font-size: ${token('fontSizes.body')}px;
        &.current {
            &:after {
                display: none;
            }
        }
    }
    /* Tablet */
    @media (max-width: 1149px) {
        padding: 0 ${token('spacing.sm')}px;
    }
`;

export const StyledIconButton = styled(IconButton)`
    @media (max-width: 889px) {
        width: 66px;
        height: 55px;
        &:before {
            content: '';
            position: absolute;
            top: 16px;
            left: 0;
            width: 1px;
            height: 25px;
            background-color: ${token('colors.borderLight')};
        }
    }
`;
