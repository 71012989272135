import styled from 'styled-components';
import { token } from '@zillow/constellation';

export const SubmenuItems = styled.ul<{ isOpen: boolean }>`
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    list-style: none;
    background-color: ${token('colors.backgroundLight')};
    padding: 0;
    flex: 1 1 100%;

    // Desktop
    @media (min-width: 890px) {
        background-color: ${token('colors.white')};

        border-right: 1px solid ${token('colors.borderLight')};
        border-left: 1px solid ${token('colors.borderLight')};
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 220px;
        z-index: 9;

        &:hover {
            display: block;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top: solid 1px ${token('colors.white')};
        }
    }
`;

export const SubmenuItem = styled.li`
    padding: 0;

    // Desktop
    @media (min-width: 890px) {
        border-bottom: solid 1px ${token('colors.borderLight')};
    }
`;

export const SubmenuLink = styled.a`
    display: flex;
    height: 50px;
    align-items: center;
    flex: 1 1 100%;
    font-size: ${token('fontSizes.bodySmall')}px;
    font-family: ${token('fonts.sansSerif')};
    padding: ${token('spacing.xs')}px ${token('spacing.sm')}px;
    color: ${token('colors.brand')};
    text-decoration: none;
    &:visited {
        color: ${token('colors.brand')};
    }
    &:hover {
        color: ${token('colors.textDark')};
        text-decoration: underline;
    }
`;
