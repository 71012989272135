import React from 'react';
import styled from 'styled-components';
import { Menu } from '../Menu';
import { SubnavProps } from './Subnav.types';

const SubnavWrapper = styled.div``;

/**
 * Subnav component renders a secondary navigation menu.
 * It acts as a wrapper around the Menu component, providing additional styling and structure.
 */
const Subnav: React.FC<SubnavProps> = ({ router, menu }) => {
    if (!menu?.items?.length) {
        return null;
    }
    return (
        <SubnavWrapper>
            <Menu router={router} menu={menu.items} />
        </SubnavWrapper>
    );
};

export default Subnav;
