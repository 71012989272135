import * as calculatePayment from '@zillow/mortgage-calculations';
import PropTypes from 'prop-types';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { LOAN_PROGRAMS, COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, LOAN_TERM_IN_MONTHS } from '../../utils/zhl-calculator.js';

var ARM_PROGRAMS = [LOAN_PROGRAMS.arm3, LOAN_PROGRAMS.arm5, LOAN_PROGRAMS.arm7];
var FieldConfig = {
  homePrice: {
    name: 'homePrice',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  loanProgram: {
    name: 'loanProgram',
    options: [{
      value: LOAN_PROGRAMS.fixed30Year,
      label: '30-year fixed'
    },
    // { value: LOAN_PROGRAMS.fixed20Year, label: '20-year fixed' },
    {
      value: LOAN_PROGRAMS.fixed15Year,
      label: '15-year fixed'
    }
    // { value: LOAN_PROGRAMS.fixed10Year, label: '10-year fixed' },
    // { value: LOAN_PROGRAMS.arm3, label: '3/1 ARM' },
    // { value: LOAN_PROGRAMS.arm5, label: '5/1 ARM' },
    // { value: LOAN_PROGRAMS.arm7, label: '7/1 ARM' },
    // { value: LOAN_PROGRAMS.arm7, label: 'Home equity 30 year' },
    // { value: LOAN_PROGRAMS.arm7, label: 'Home equity 30 year due in 15' },
    // { value: LOAN_PROGRAMS.arm7, label: 'Home equity 15 year' },
    // { value: LOAN_PROGRAMS.arm7, label: 'HELOC 20 year' },
    // { value: LOAN_PROGRAMS.arm7, label: 'HELOC 15 year' },
    // { value: LOAN_PROGRAMS.arm7, label: 'HELOC 10 year' },
    // { value: LOAN_PROGRAMS.arm7, label: 'Low or no down' },
    // { value: LOAN_PROGRAMS.arm7, label: 'Interest-only' },
    ],
    config: {
      required: 'Required',
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(LOAN_PROGRAMS).includes(value) === false) {
            error = 'Invalid loan program';
          }
          return error;
        }
      }
    }
  },
  downPayment: {
    name: 'downPayment',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPaymentPercent: {
    name: 'downPaymentPercent',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  interestRate: {
    name: 'interestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  includePMI: {
    name: 'includePMI'
  },
  includeTaxesInsurance: {
    name: 'includeTaxesInsurance'
  },
  taxRate: {
    name: 'taxRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  homeInsurance: {
    name: 'homeInsurance',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  hoa: {
    name: 'hoa',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  }
};
var DEFAULT_VALUES_SHAPE = {
  homePrice: PropTypes.number,
  loanProgram: PropTypes.string,
  downPayment: PropTypes.number,
  interestRate: PropTypes.number,
  taxRate: PropTypes.number,
  homeInsurance: PropTypes.number,
  hoa: PropTypes.number
};
function useLastValidMortgageValues(form) {
  return useLastValidValues(form, getMortgageValues);
}
function getMortgageValues(lastValidFields) {
  var loanAmount = lastValidFields.homePrice - lastValidFields.downPayment;
  var monthlyPI = calculatePayment.monthlyPI(loanAmount, lastValidFields.interestRate, LOAN_TERM_IN_MONTHS[lastValidFields.loanProgram]);
  var isARM = ARM_PROGRAMS.includes(lastValidFields.loanProgram);
  var monthlyPMI = calculatePayment.monthlyPMI(loanAmount, lastValidFields.homePrice, isARM);
  var monthlyTaxes = lastValidFields.homePrice * (lastValidFields.taxRate / 100) / 12;
  var monthlyInsurance = lastValidFields.homeInsurance / 12;
  var monthlyPayment = monthlyPI + (lastValidFields.includePMI ? monthlyPMI : 0) + (lastValidFields.includeTaxesInsurance ? monthlyInsurance + monthlyTaxes : 0) + lastValidFields.hoa;
  var calculations = {
    loanAmount: loanAmount,
    monthlyPI: monthlyPI,
    monthlyPMI: monthlyPMI,
    monthlyTaxes: monthlyTaxes,
    monthlyInsurance: monthlyInsurance,
    monthlyPayment: monthlyPayment
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}

export { DEFAULT_VALUES_SHAPE, FieldConfig, useLastValidMortgageValues };
