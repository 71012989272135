import { Box, IconClose, IconMenu } from '@zillow/constellation';
import React, { useState } from 'react';
import { MenuItem } from '../MenuItem';
import { MenuItems, menuStyles, menuToggleStyles } from './Menu.styles';
import type { MenuProps } from './Menu.types';

/**
 * Main Menu component that renders a navigation menu with a toggle button.
 */
const Menu: React.FC<MenuProps> = ({ menu }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const ToggleIcon = isMenuOpen ? IconClose : IconMenu;

    return (
        <nav>
            <Box sx={menuToggleStyles} aria-expanded={isMenuOpen}>
                <ToggleIcon
                    aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
                    size={isMenuOpen ? 'xs' : 'sm'}
                    fontColor="brand"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
            </Box>
            <Box sx={menuStyles}>
                <MenuItems menuOpen={isMenuOpen}>
                    {menu
                        .filter((menuItem) => !menuItem.parent)
                        .map((menuItem) => (
                            <MenuItem
                                key={menuItem.id}
                                menuItem={menuItem}
                                menu={menu}
                                mobileOnly={false}
                            />
                        ))}
                    {/* Go to Zillow Link for mobile */}
                    <MenuItem
                        key="home"
                        mobileOnly
                        menuItem={{
                            id: 'home',
                            title: { rendered: 'Go to Zillow' },
                            url: '/',
                        }}
                        menu={menu}
                    />
                </MenuItems>
            </Box>
        </nav>
    );
};

export default Menu;
