import { token } from '@zillow/constellation';
import { GROUP_WIDTH_MAP } from '@zillow/zrs-cms';
import styled from 'styled-components';
import type { MenuItemsProps } from './Menu.types';

export const MenuItems = styled.ol<MenuItemsProps>`
    display: flex;
    align-content: stretch;
    align-items: center;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    max-width: ${GROUP_WIDTH_MAP.xl};
    /* Mobile */
    @media (max-width: 889px) {
        padding: 0;
        display: ${(props) => (props.menuOpen ? 'block' : 'none')};
        position: absolute;
        background-color: ${token('colors.white')};
        z-index: ${token('zIndices.popover')};
        width: 100%;
    }
`;

export const menuToggleStyles = {
    position: 'absolute',
    left: '15px',
    top: '19px',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '$zIndices.popover',
    backgroundColor: 'white',
    '@media (min-width: 889px)': {
        display: 'none',
    },
};

export const menuStyles = {
    borderBottom: 'solid 1px $colors.borderLight',
    backgroundColor: '$colors.white',
    '@media (max-width: 890px)': {
        borderBottom: 'none',
    },
};
