import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import querystring from 'querystring';
import { useState, useEffect } from 'react';
import calculatePayment__default from '@zillow/mortgage-calculations';
import { isWebview } from '@zillow/mortgage-webview-query-params';
import { LOAN_PROGRAMS, COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, LOAN_TERM_IN_MONTHS } from '../../utils/zhl-calculator.js';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import PropTypes from 'prop-types';

var ARM_PROGRAMS = [LOAN_PROGRAMS.arm3, LOAN_PROGRAMS.arm5, LOAN_PROGRAMS.arm7];
var DEFAULT_VALUES_SHAPE = {
  homePrice: PropTypes.number,
  zipCode: PropTypes.number,
  loanProgram: PropTypes.number,
  downPayment: PropTypes.number,
  downPaymentPercent: PropTypes.number,
  interestRate: PropTypes.number,
  taxRate: PropTypes.number,
  taxRatePercent: PropTypes.number,
  homeInsurance: PropTypes.number,
  hoa: PropTypes.number,
  includeMIP: PropTypes.bool,
  includeTaxesInsurance: PropTypes.bool
};
var FieldConfig = {
  homePrice: {
    name: 'homePrice',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPayment: {
    name: 'downPayment',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPaymentPercent: {
    name: 'downPaymentPercent',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  zipCode: {
    name: 'zipCode'
  },
  loanProgram: {
    name: 'loanProgram',
    options: [{
      value: LOAN_PROGRAMS.fixed30Year,
      label: '30-year fixed'
    }, {
      value: LOAN_PROGRAMS.fixed15Year,
      label: '15-year fixed'
    }, {
      value: LOAN_PROGRAMS.arm5,
      label: '5-year ARM'
    }],
    config: {
      required: 'Required',
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(LOAN_PROGRAMS).includes(value) === false) {
            error = 'Invalid loan program';
          }
          return error;
        }
      }
    }
  },
  fhaLoanProgram: {
    name: 'loanProgram',
    options: [{
      value: LOAN_PROGRAMS.fixed30Year,
      label: '30-year fixed'
    }, {
      value: LOAN_PROGRAMS.fixed15Year,
      label: '15-year fixed'
    }],
    config: {
      required: 'Required',
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(LOAN_PROGRAMS).includes(value) === false) {
            error = 'Invalid loan program';
          }
          return error;
        }
      }
    }
  },
  interestRate: {
    name: 'interestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  includeTaxesInsurance: {
    name: 'includeTaxesInsurance'
  },
  includeMIP: {
    name: 'includeMIP'
  },
  taxRate: {
    name: 'taxRate',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  taxRatePercent: {
    name: 'taxRatePercent',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  homeInsurance: {
    name: 'homeInsurance',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  hoa: {
    name: 'hoa',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  }
};
function useLastValidPayemntValues(form) {
  return useLastValidValues(form, getPayementValues);
}
function getPayementValues(lastValidFields) {
  var calculations = calculatePayment__default({
    term: LOAN_TERM_IN_MONTHS[lastValidFields.loanProgram],
    homePrice: lastValidFields.homePrice,
    downPayment: lastValidFields.downPayment,
    rate: lastValidFields.interestRate,
    includeHOA: true,
    monthlyHOA: lastValidFields.hoa,
    includeHomeownersInsurance: lastValidFields.includeTaxesInsurance,
    annualHomeownersInsurance: lastValidFields.homeInsurance,
    includePropertyTax: lastValidFields.includeTaxesInsurance,
    propertyTaxAmount: lastValidFields.taxRate,
    propertyTaxRate: lastValidFields.taxRatePercent,
    includePMI: false,
    includeTaxesInsurance: true,
    isARM: ARM_PROGRAMS.includes(lastValidFields.loanProgram),
    dti: 0
  });
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}
var getZillowURL = function getZillowURL(path) {
  var zillowOrigin = 'https://www.zillow.com';
  return zillowOrigin + "/" + path.replace(/^\//, '');
};
var useZillowURL = function useZillowURL(path) {
  var _useState = useState(getZillowURL(path)),
    _useState2 = _slicedToArray(_useState, 2),
    url = _useState2[0],
    setURL = _useState2[1];
  useEffect(function () {
    setURL(getZillowURL(path));
  }, [path]);
  return url;
};
function useCustomQuotesURL(pathBase, params) {
  if (pathBase === void 0) {
    pathBase = 'mortgage-rates';
  }
  if (params === void 0) {
    params = {};
  }
  var _useState3 = useState(pathBase + '/'),
    _useState4 = _slicedToArray(_useState3, 2),
    URI = _useState4[0],
    setURI = _useState4[1];
  useEffect(function () {
    var path = isWebview() ? 'mortgage/webviews/ZCQ.htm' : pathBase + '/';
    var queryString = querystring.stringify(_omitBy(params, _isNil));
    if (queryString) {
      queryString = "?" + queryString;
    }
    setURI(path + queryString);
  }, [pathBase, params]);
  return useZillowURL(URI);
}
var safe = function safe(fn, fallback) {
  if (fallback === void 0) {
    fallback = null;
  }
  return function (x) {
    return x ? fn(x) : fallback;
  };
};
function isSameFloat(float1, float2) {
  return Math.abs(Number(float1.toFixed(2)) - Number(float2.toFixed(2))) < Number.EPSILON;
}
function calculateMIPRate(ltv, loanAmount, loanprogram) {
  var monthlyMIP;
  if (loanprogram == 'Fixed30Year') {
    if (ltv > 90 && ltv <= 95) {
      monthlyMIP = loanAmount <= 726200 ? 0.005 : 0.007;
    }
    if (ltv > 95) {
      monthlyMIP = loanAmount <= 726200 ? 0.0055 : 0.0075;
    }
  } else {
    if (loanAmount >= 726200) {
      if (ltv <= 78) {
        monthlyMIP = 0.0015;
      }
      if (ltv >= 78 && ltv <= 90) {
        monthlyMIP = 0.004;
      }
      if (ltv > 90) {
        monthlyMIP = 0.0065;
      }
    }
  }
  return monthlyMIP / 12;
}
function calculateMonthlyMIP(loanAmountValue, propertyValue, loanProgram) {
  var ltv = loanAmountValue / propertyValue * 100;
  var mipRate = calculateMIPRate(ltv, loanAmountValue, loanProgram);
  var monthlyMIP = mipRate * loanAmountValue / 12;
  return monthlyMIP;
}
var vaCalculatorDisclaimerText = 'The VA mortgage calculator is intended to be used for educational purposes only. Actual available rates and monthly payment amounts are subject to market fluctuations and will depend on a number of factors, including geography and loan characteristics. The estimates are based on information you provide, and may not include other fees and costs that a lender may assess in addition to monthly principal and interest, such VA funding fee, other taxes and insurance,and the actual payment obligation may be greater. Zillow Group Marketplace, Inc. does not make loans and this is not a commitment to lend.';
var fhaCalculatorDisclaimerText = 'The FHA mortgage calculator is intended to be used for educational purposes only. Actual available rates and monthly payment amounts are subject to market fluctuations and will depend on a number of factors, including geography and loan characteristics. The estimates are based on information you provide, and may not include other fees and costs that a lender may assess in addition to monthly principal and interest, such as taxes and insurance and the actual payment obligation may be greater. Zillow Group Marketplace, Inc. does not make loans and this is not a commitment to lend.';

export { DEFAULT_VALUES_SHAPE, FieldConfig, calculateMonthlyMIP, fhaCalculatorDisclaimerText, getPayementValues, getZillowURL, isSameFloat, safe, useCustomQuotesURL, useLastValidPayemntValues, vaCalculatorDisclaimerText };
