import React from 'react';
import { SubmenuItem, SubmenuItems, SubmenuLink } from './Submenu.styled';
import type { SubmenuProps } from './Submenu.types';

/**
 * Submenu component renders a dropdown/nested menu based on parent-child relationships
 */
const Submenu: React.FC<SubmenuProps> = ({ menu, parentId, isOpen }) => {
    const submenuItems = menu.filter(
        (menuItem) => menuItem.parent.toString() === parentId
    );
    if (!submenuItems.length) {
        return null;
    }

    return (
        <SubmenuItems className="subnav-menu-submenu" isOpen={isOpen}>
            {submenuItems.map((menuItem) => (
                <SubmenuItem key={menuItem.id}>
                    <SubmenuLink
                        href={menuItem.url}
                        title={menuItem?.title?.rendered}
                        target={menuItem.target}
                        id={menuItem.attr_id}
                    >
                        {menuItem?.title?.rendered}
                    </SubmenuLink>
                </SubmenuItem>
            ))}
        </SubmenuItems>
    );
};

export default Submenu;
