import { getCmsInfoForPage } from '@zillow/zrs-cms';
import type { TMenuItem } from '../Menu';
import type { EventData, TCmsInfo } from '../../types';

type CreateEventDataParams = {
    eventTypeId: string;
    eventTemplateId: string;
    semanticEventName: string;
    triggerSourceName: string;
    menuItem: TMenuItem;
    pageProps: any;
    additionalCmsInfo?: Partial<TCmsInfo>;
};

const createEventData = ({
    eventTypeId,
    eventTemplateId,
    semanticEventName,
    triggerSourceName,
    menuItem,
    pageProps,
    additionalCmsInfo = {},
}: CreateEventDataParams): EventData => ({
    envelope: {
        event_type_id: eventTypeId,
        event_type_version_id: '1',
        event_template_id: eventTemplateId,
        event_template_version_id: '1',
    },
    clickstream_trigger: {
        trigger_type_nm: 'interaction',
        trigger_location_nm: 'cms',
        trigger_source_nm: triggerSourceName,
        trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
        semantic_event_nm: semanticEventName,
    },
    cms_info: {
        block_id: menuItem?.attr_id || '',
        block_type_cd: 'subnav',
        block_displayed_txt: menuItem?.title?.rendered,
        ...getCmsInfoForPage(pageProps),
        ...additionalCmsInfo,
    },
});

/**
 * Triggers an event when the submenu is opened.
 *
 * @link https://dataportal.zillowgroup.net/event-registry/5030
 *
 * @param menuItem - The menu item configuration object.
 * @param pageProps - The current page's properties.
 *
 * @returns An event data object for analytics tracking.
 */
export const getSubMenuOpenEventData = (
    menuItem: TMenuItem,
    pageProps: any
): EventData =>
    createEventData({
        eventTypeId: '5030',
        eventTemplateId: '297',
        semanticEventName: 'cms_open',
        triggerSourceName: 'cms_subnav',
        menuItem,
        pageProps,
    });

/**
 * Triggers an event when the submenu is closed.
 *
 * @link https://dataportal.zillowgroup.net/event-registry/2051
 *
 * @param menuItem - The menu item configuration object.
 * @param pageProps - The current page's properties.
 *
 * @returns An event data object for analytics tracking.
 */
export const getSubMenuCloseEventData = (
    menuItem: TMenuItem,
    pageProps: any
): EventData =>
    createEventData({
        eventTypeId: '2051',
        eventTemplateId: '126',
        semanticEventName: 'cms_close',
        triggerSourceName: 'cms_subnav',
        menuItem,
        pageProps,
    });

/**
 * Trigger an event when the menu item is hovered.
 *
 * @link https://dataportal.zillowgroup.net/event-registry/2049
 *
 * @param menuItem - The menu item configuration object.
 * @param pageProps - The current page's properties.
 *
 * @returns An event data object for analytics tracking.
 */
export const getSubMenuHoverEventData = (
    menuItem: TMenuItem,
    pageProps: any
): EventData =>
    createEventData({
        eventTypeId: '2049',
        eventTemplateId: '126',
        semanticEventName: 'cms_hover',
        triggerSourceName: 'cms_subnav',
        menuItem,
        pageProps,
    });

/**
 * Triggers an event when the menu item is clicked.
 *
 * @link https://dataportal.zillowgroup.net/event-registry/2048
 *
 * @param menuItem - The menu item configuration object.
 * @param pageProps - The current page's properties.
 * @param event - The click event from the menu item interaction.
 *
 * @returns An event data object for analytics tracking.
 */
export const getSubMenuClickEventData = (
    menuItem: TMenuItem,
    pageProps: any,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
): EventData =>
    createEventData({
        eventTypeId: '2048',
        eventTemplateId: '126',
        semanticEventName: 'cms_click',
        triggerSourceName: 'cms_anchor',
        menuItem,
        pageProps,
        additionalCmsInfo: {
            block_id: (event.target as HTMLAnchorElement).id || '',
            block_displayed_txt:
                (event.target as HTMLAnchorElement).textContent || '',
            block_target_url: menuItem.url,
            block_target_window_txt: menuItem.target || '',
        },
    });
