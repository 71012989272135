import { IconChevronDown, IconChevronUp } from '@zillow/constellation';
import { event } from '@zillow/universal-analytics';
import { usePageProps } from '@zillow/zrs-cms';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Submenu } from '../Submenu';
import {
    MenuItemContainer,
    MenuLink,
    StyledIconButton,
} from './MenuItem.styles';
import {
    getSubMenuClickEventData,
    getSubMenuCloseEventData,
    getSubMenuHoverEventData,
    getSubMenuOpenEventData,
} from './MenuItem.telemetry';
import type { MenuItemProps } from './MenuItem.types';

/**
 * Renders a single menu item with submenu functionality.
 */
const MenuItem: React.FC<MenuItemProps> = ({ menu, menuItem, mobileOnly }) => {
    const router = useRouter();
    const pageProps = usePageProps();
    const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const onSubmenuOpen = () => {
        if (!hasChildren) {
            return;
        }

        setIsSubmenuOpen(true);
        event(getSubMenuOpenEventData(menuItem, pageProps));
    };

    const onSubmenuClose = () => {
        if (!hasChildren) {
            return;
        }
        setIsSubmenuOpen(false);
        event(getSubMenuCloseEventData(menuItem, pageProps));
    };

    useEffect(() => {
        const onResize = () => {
            setIsMobile(window.innerWidth < 890);
        };
        const debouncedOnResize = debounce(onResize, 100);

        setIsMobile(window.innerWidth < 890);
        // eslint-disable-next-line no-undef
        window.addEventListener('resize', debouncedOnResize);
        window.addEventListener('orientationchange', debouncedOnResize);

        return () => {
            // eslint-disable-next-line no-undef
            window.removeEventListener('resize', debouncedOnResize);
            window.removeEventListener('orientationchange', debouncedOnResize);
        };
    }, []);

    let path = '';
    try {
        const url = new URL(menuItem.url);
        path = url.pathname;
    } catch (error) {
        console.warn(`Failed to parse URL: ${menuItem.url}`);

        if (menuItem.url.startsWith('#')) {
            path = router.pathname;
        } else {
            path = '';
        }
    }

    const hasChildren = menu.some(
        (item) => item.parent.toString() === menuItem.id
    );

    // Format the slug from the router to match the path
    let { slug = ['/'] } = router.query;
    slug = Array.isArray(slug) ? slug.join('/') : slug;
    slug = `/${slug}/`;

    return (
        <MenuItemContainer
            mobileOnly={mobileOnly}
            onMouseEnter={() => {
                if (!isMobile) {
                    event(getSubMenuHoverEventData(menuItem, pageProps));
                    onSubmenuOpen();
                }
            }}
            onMouseLeave={() => {
                if (!isMobile) {
                    onSubmenuClose();
                }
            }}
        >
            <MenuLink
                href={menuItem.url}
                title={menuItem?.title?.rendered}
                target={menuItem.target}
                className={path === slug ? 'current' : ''}
                id={menuItem.attr_id}
                onClick={(e) => {
                    event(getSubMenuClickEventData(menuItem, pageProps, e));
                }}
            >
                {menuItem?.title?.rendered}
            </MenuLink>
            {hasChildren && (
                <StyledIconButton
                    bare
                    size="sm"
                    icon={
                        isSubmenuOpen ? <IconChevronUp /> : <IconChevronDown />
                    }
                    buttonType="secondary"
                    onClick={() => {
                        if (isSubmenuOpen) {
                            onSubmenuClose();
                        } else {
                            onSubmenuOpen();
                        }
                    }}
                />
            )}
            <Submenu
                menu={menu}
                parentId={menuItem.id}
                isOpen={isSubmenuOpen}
            />
        </MenuItemContainer>
    );
};

export default MenuItem;
