import styled from 'styled-components';
import { Banner } from '@zillow/constellation';

export const StyledBanner = styled(Banner)`
    position: fixed;
    bottom: 2.5rem;

    /* Take full width but centere it with 2.5% horizontal margins. */
    left: 2.5%;
    /*  2.5% * 2 is 5% and 100% - 5% is 95%. */
    width: 95%;

    /* Copied from Toast */
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);

    /* Using 1,000 puts it above Zillow header. */
    z-index: 1000;
`;
